import { cx } from "class-variance-authority"
import { convertFromRaw, Editor, EditorState } from "draft-js"
import Image from "next/image"
import Link from "next/link"
import { useRouter } from "next/router"
import { FC, useContext, useEffect, useRef, useState } from "react"
import IconText from "src/views/components/UI/IconText"
import Spinner from "src/views/components/UI/loaders/Spinner"
import Typography from "src/views/components/typography"
import ApplyButton from "src/views/components/Vacancy/ApplyButton"
import useIndex from "src/server/queryHooks/useIndex"
import useVacancy, { getSimilarVacancy } from "src/server/queryHooks/useVacancy"
import { displayLanguageLevelKa, getLanguageTitles } from "src/utils/helpers/language/languageFunctionts"
import { useScreenSize } from "src/utils/helpers/useScreenSize"
import {
  RenderVacancyExperience,
  translateDegreeToGeorgian,
  translateLevelToGeorgian
} from "src/utils/helpers/vacancy/renderVacancyExperience"
import { renderVacancyLocation } from "src/utils/helpers/vacancy/renderVacancyLocation"
import AboutCompanyCard from "src/views/components/Cards/AboutCompanyCard"
import { CardPin } from "src/views/components/Cards/Card.styles"
import AverageSalaryStatistic from "../../../components/Vacancy/AverageSalaryAnalytics"
import { getAverageVacancySalary } from "../../../../utils/helpers/string/convertSalaryVariantsToText"
import { getEmploymenTypeLabel, getJobTypeLabel } from "../../../../types/vacancy/workOptions"
import VacancyItemScrollHeader from "../VacancyContentScrollHeader/VacancyItemScrollHeader"
import { IVacancy } from "../../../../types/vacancyTypes"
import IconButton from "src/views/components/common/Button/IconButton"
import {
  ACCHeader,
  ACCHeaderCompanyWrapper,
  ACCHeaderLogoCompanyWrapper,
  ACCLayout,
  ACCNotificationButton
} from "src/views/components/Cards/AboutCompanyCard/styles.AboutCompanyCard"
import CompanyLogo from "src/views/components/GeneralLogo/CompanyLogo"
import Cookie from "src/utils/configs/Cookie"
import useNotifications, { postSubscribeCompany, postUnsubscribeCompany } from "src/server/queryHooks/useNotifications"
import { useMutation } from "@tanstack/react-query"
import { ACCESS_TOKEN_NAME, STATIC_URL } from "src/env"
import { ResumeApplyContext } from "src/context/ResumeApplyProvider"
import Button from "src/views/components/common/Button/Button"
import VacancyCards from "src/views/components/Cards/VacancyCards"
import { VacanciesListBoxList } from "../VacanciesList/VacanciesList.styles"
import VacanciesListItem from "../VacanciesListItem/VacanciesListItem"
import Vip from "src/views/components/Vacancy/Vip/Vip"
import { twMerge } from "tailwind-merge"
import { renderVacancySalary } from "src/utils/helpers/vacancy/renderVacancySalary"
import { renderVacancySalaryPeriod } from "src/utils/helpers/vacancy/renderVacancySalaryPeriod"
import moment from "moment"
import { useTranslation } from "next-i18next"

interface Props {
  isSingleVacancyPage?: boolean
  vacancy: IVacancy
  isLoading: boolean
  hidden?: boolean
  onClose?: any
  openSimilarVacancy?: any
}
const VacancyMobileContent: FC<Props> = ({
  isSingleVacancyPage,
  vacancy,
  isLoading,
  hidden = true,
  onClose,
  openSimilarVacancy
}) => {
  const { t } = useTranslation("common")
  const { langSkillsCountriesQuery } = useVacancy()
  const APILANGUAGES = langSkillsCountriesQuery?.data?.languages
  const divRef = useRef<HTMLDivElement>(null)
  const scrollRef = useRef<HTMLDivElement>(null)
  const [showAllBenefits, setShowAllBenefits] = useState(false)
  const [showAllSkills, setShowAllSkills] = useState(false)
  const { benefitsQuery } = useIndex()
  const router = useRouter()
  let editorState = EditorState.createEmpty()

  const { vacancySavedAppliedStatus, deleteSavedVacancyMutation, saveVacancyMutation } = useVacancy()
  const { isMd } = useScreenSize()

  const isSaved = vacancySavedAppliedStatus.data?.saved[vacancy.id]
  const loadingSaved = saveVacancyMutation.isLoading || deleteSavedVacancyMutation.isLoading
  const toggleSaveVacancy = () => {
    if (!loadingSaved) {
      isSaved ? deleteSavedVacancyMutation.mutate(vacancy.id) : saveVacancyMutation.mutate(vacancy.id)
    }
  }

  const { subscriptionsQuery } = useNotifications()

  const subscribedCompanyIds = subscriptionsQuery.data
  const subscribeCompanyMutation = useMutation({
    mutationFn: postSubscribeCompany,
    onSuccess: () => {
      subscriptionsQuery.refetch()
    }
  })

  const unsubscribeCompanyMutation = useMutation({
    mutationFn: postUnsubscribeCompany,
    onSuccess: () => {
      subscriptionsQuery.refetch()
    }
  })
  const isCompanyLoading =
    unsubscribeCompanyMutation.isLoading ||
    subscribeCompanyMutation.isLoading ||
    subscriptionsQuery.isLoading ||
    subscriptionsQuery.isFetching

  const handleCompanySubscribe = () => {
    if (!isLoading) {
      if (subscribedCompanyIds?.includes(vacancy.company_id)) {
        unsubscribeCompanyMutation.mutate(vacancy.company_id)
      } else {
        subscribeCompanyMutation.mutate(vacancy.company_id)
      }
    }
  }

  const [similarVacancies, setSimilarVacancies] = useState([])

  useEffect(() => {
    getSimilarVacancies(vacancy)
  }, [])

  const getSimilarVacancies = (similarVacancy: any) => {
    getSimilarVacancy(similarVacancy.id).then(data => {
      setSimilarVacancies(data)
    })
  }

  const scrollToTop = () => {
    if (scrollRef.current) {
      scrollRef.current.scrollIntoView({ behavior: "smooth" })
    }
  }

  const { setVacancyId } = useContext(ResumeApplyContext)
  const isApplied = vacancySavedAppliedStatus?.data?.applied[vacancy.id]

  const isMdSingleVacancy = isMd && router.pathname === "/vacancy/[id]"
  if (isLoading) {
    return (
      //   <div
      //     className={cx(
      //       "relative col-span-6  w-full border-neutral-40",
      //       isSingleVacancyPage ? "mx-auto mt-8 max-w-[1032px] rounded-xl border bg-white" : "border-l"
      //     )}
      //   >
      //     <div
      //       className={cx(
      //         `flex h-full items-center justify-center overflow-auto overscroll-contain px-6 py-4`,
      //         isSingleVacancyPage && "py-6"
      //       )}
      //       ref={divRef}
      //     >
      //       <Image src={"/gifs/content_search.gif"} width={360} height={360} alt="dsd" />
      //     </div>
      //   </div>
      <></>
    )
  }

  const translate = (values: any) => {
    return values.map((value: any) => {
      return t(value.toUpperCase())
    })
  }

  const translateLevel = (levels: any) => {
    return levels?.length ? `- ${levels.map((level: any) => t(level.experience_level.toUpperCase())).join(", ")}` : ""
  }

  if (!vacancy) return null

  try {
    const contentState = convertFromRaw(JSON.parse(vacancy.description))
    editorState = EditorState.createWithContent(contentState)
  } catch (error) {
    console.log("Error parsing description:", vacancy.id)
  }

  return (
    <div
      className={cx(
        "relative col-span-6   border-neutral-40 md:block",
        isSingleVacancyPage ? "mx-auto mt-8 max-w-[1032px] rounded-xl border bg-white" : "border-l",
        isMd && router.pathname !== "/vacancy/[id]" && hidden ? "hidden md:block" : ""
      )}
    >
      <div
        style={{ height: `calc(${window.innerHeight}px - 50px)`, paddingBottom: "100px" }}
        className={cx(`h-full overflow-auto overscroll-contain px-4 py-4`, isSingleVacancyPage && "py-6")}
      >
        <div className={cx("flex items-center justify-between")}>
          <div ref={scrollRef} className="flex flex-col gap-2">
            <Typography type="h4">{vacancy.title}</Typography>

            {/* <div className="flex items-center gap-2">
              {!isMdSingleVacancy && (
                <Typography type="text" color="dark" className="line-clamp-1 grow-0 underline">
                  {vacancy.company.brand_name}
                </Typography>
              )}
              {!isMdSingleVacancy && <Image src="/images/ellipse.svg" width={4} height={4} alt="ellipse" />}
              {!isMdSingleVacancy && (
                <Typography type="text" className="whitespace-pre">
                  {renderVacancyLocation(vacancy.country)}
                </Typography>
              )}
              {!isMdSingleVacancy && <Image src="/images/ellipse.svg" width={4} height={4} alt="ellipse" />}
              <Typography type="small" className="shrink-0">
                {vacancy.view_count} ნახვა
              </Typography>
              <Image src="/images/ellipse.svg" width={4} height={4} alt="ellipse" />
              <Typography type="small" className="shrink-0 text-primary-100">
                {vacancy.applicants_count} აპლიკანტი
              </Typography>
            </div> */}
          </div>
          {/* <div className="h-6 w-6 rounded-full bg-[#EEEEEE]">
            <IconButton src="/images/close.svg" onClick={() => {}} />
          </div> */}
          <button
            type="button"
            onClick={onClose}
            className="flex h-6 w-6 items-center justify-center rounded-full bg-[#EEEEEE]"
          >
            <Image src="/images/close.svg" alt="" width={16} height={16} />
          </button>
        </div>
        <ACCHeaderLogoCompanyWrapper className="py-3">
          <div className="relative h-12 w-12">
            <CompanyLogo
              logo_version={vacancy.company.logo_version}
              company_id={vacancy.company.id}
              has_logo={vacancy.company?.has_logo}
            />
          </div>
          <ACCHeaderCompanyWrapper>
            <Typography type="text" color="dark">
              {vacancy.company.brand_name}
            </Typography>
            <Typography type="text">{vacancy.company?.legal_city_title}</Typography>
          </ACCHeaderCompanyWrapper>
        </ACCHeaderLogoCompanyWrapper>
        {/* <Typography className="mt-2.5 text-primary-100" type="text" weight="medium">
          ID {vacancy.id}
        </Typography> */}
        <div className="flex items-center gap-2">
          {!isMdSingleVacancy && (
            <Typography type="text" className="whitespace-pre">
              {renderVacancyLocation(vacancy.country)}
            </Typography>
          )}
          {!isMdSingleVacancy && <Image src="/images/ellipse.svg" width={4} height={4} alt="ellipse" />}
          <Typography type="small" className="shrink-0 text-primary-100">
            {vacancy.applicants_count} {t("APLICANT")}
          </Typography>
        </div>
        <div className="mb-2 mt-2.5">
          {vacancy.salary_from && vacancy.show_salary ? (
            <div className="flex items-center gap-1 ">
              <IconText
                icon="/images/lari_big.svg"
                width={24}
                height={24}
                text={
                  vacancy.show_salary
                    ? `${vacancy.salary_from} ${vacancy.salary_to ? " - " + vacancy.salary_to : ""}`
                    : t("NEGOTIABLE")
                }
                type="h5"
                weight="medium"
              />
              <Typography type="small" className="">
                / {t((vacancy.salary_period || "").toUpperCase())}
              </Typography>
            </div>
          ) : (
            <Typography type="h5" weight="medium">
              {t("NEGOTIABLE")}
            </Typography>
          )}
        </div>

        <div className="flex flex-col gap-2">
          <div>
            <IconText
              icon="/images/watch_later.svg"
              text={t(getEmploymenTypeLabel(vacancy.employment_type))}
              type="small"
            />

            <IconText
              className="mt-2"
              icon="/images/work_outline.svg"
              text={t(getJobTypeLabel(vacancy.job_type))}
              type="small"
            />
            <IconText
              icon="/images/star_half.svg"
              text={`${RenderVacancyExperience(
                vacancy.work_experiences && vacancy.work_experiences[0]?.experience_from,
                vacancy.work_experiences && vacancy.work_experiences[0]?.experience_to
              )} ${translateLevel(vacancy?.experience_levels)}`}
              type="small"
              className="mt-2"
            />
            <IconText
              icon="/images/school.svg"
              text={translate(vacancy.education_levels).join(", ")}
              type="small"
              className="mt-2"
            />
          </div>

          {/* Languages Section */}
          <div className="mt-1">
            <Typography type="text" weight="medium">
              {t("LANGUAGES")}
            </Typography>
            <div className="mt-3 flex flex-wrap gap-2">
              {vacancy.languages?.map((language: any, idx: any) => (
                <div className="flex items-center gap-1.5 rounded-[65px] bg-neutral-20 px-4 py-2" key={idx}>
                  <Typography type="small" weight="medium">
                    {getLanguageTitles(APILANGUAGES, [language.language_id])}
                  </Typography>
                  <Typography type="small" className="text-secondary-80">
                    ({t(displayLanguageLevelKa(language.level))})
                  </Typography>
                </div>
              ))}
            </div>
            {/* <div className="flex items-center gap-1.5 rounded-[65px] bg-neutral-20 px-4 py-2">
                <Typography type="small" weight="medium">
                  ქართული
                </Typography>
                <Typography type="small" className="text-secondary-80">
                  (მშობლიური)
                </Typography>
              </div>
              {vacancy.id % 2 === 0 ? (
                <div className="flex items-center gap-1.5 rounded-[65px] bg-neutral-20 px-4 py-2">
                  <Typography type="small" weight="medium">
                    ინგლისური
                  </Typography>
                  <Typography type="small" className="text-secondary-80">
                    (კარგი)
                  </Typography>
                </div>
              ) : null}

              {vacancy.id % 3 === 0 ? (
                <div className="flex items-center gap-1.5 rounded-[65px] bg-neutral-20 px-4 py-2">
                  <Typography type="small" weight="medium">
                    რუსული
                  </Typography>
                  <Typography type="small" className="text-secondary-80">
                    (კარგი)
                  </Typography>
                </div>
              ) : null}
            </div> */}
          </div>

          {/* Skills Section */}
          <div className="mt-4">
            <Typography type="text" weight="medium">
              {t("PROFESSIONAL_TRAITS")}
            </Typography>
            <div className="flex">
              <div className="scrollbar-hidden mt-3 flex gap-2 overflow-scroll">
                {vacancy.skills.length > 0
                  ? vacancy.skills.map((skill: string) => (
                      <div className="flex items-center gap-1.5 rounded-[65px] bg-neutral-20 px-4 py-2" key={skill}>
                        <Typography className="skills-text" type="small" weight="medium">
                          {skill || "React"}
                        </Typography>
                      </div>
                    ))
                  : null}
              </div>
            </div>
          </div>

          <div className="mt-4">
            <Typography type="text" weight="medium" className="mb-3">
              {t("BENEFITS")}
            </Typography>
            <div className="flex items-start justify-between">
              <div className={cx("flex flex-wrap items-start gap-3 gap-y-2", isSingleVacancyPage && "mt-2")}>
                {vacancy?.benefits?.map((benefit: any) => {
                  const currentBenefit = benefitsQuery?.data?.find(benefitApi => benefitApi.id === benefit.id)
                  if (!currentBenefit) return null
                  return (
                    <CardPin key={currentBenefit.id}>
                      {/* {benefits.find((benefit: any) => benefit.id === id)?.icon && (} */}
                      <RenderBenefitSvg icon={currentBenefit.icon} id={currentBenefit.id} />
                      <Typography type="small" className="line-clamp-1">
                        {t(currentBenefit.title)}
                      </Typography>
                    </CardPin>
                  )
                })}
              </div>
            </div>
          </div>
        </div>
        {!isSingleVacancyPage && (
          <div ref={divRef} className="mb-1 mt-5 flex items-center gap-2">
            <div
              className={cx(
                "flex w-1/2 cursor-pointer items-center justify-center rounded-full border border-primary-100 p-2 text-primary-100 transition-all",
                isSaved ? "bg-primary-5 hover:bg-primary-10" : "hover:bg-neutral-20"
              )}
              onClick={() => toggleSaveVacancy()}
            >
              {loadingSaved ? (
                <Spinner />
              ) : isSaved ? (
                <>
                  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path
                      d="M14.0908 2.63647H5.90901C5.00901 2.63647 4.27264 3.37284 4.27264 4.27284V17.3637L9.99992 14.9092L15.7272 17.3637V4.27284C15.7272 3.37284 14.9908 2.63647 14.0908 2.63647Z"
                      fill="#A155B9"
                    />
                  </svg>
                  <p className="pl-1">{t("SAVED")}</p>
                </>
              ) : (
                <>
                  <svg width="20" height="20" viewBox="0 0 20 20" fill="#fff" xmlns="http://www.w3.org/2000/svg">
                    <path
                      d="M14.0908 2.63647H5.90901C5.00901 2.63647 4.27264 3.37284 4.27264 4.27284V17.3637L9.99992 14.9092L15.7272 17.3637V4.27284C15.7272 3.37284 14.9908 2.63647 14.0908 2.63647ZM14.0908 14.9092L9.99992 13.1256L5.90901 14.9092V5.09102C5.90901 4.64102 6.27719 4.27284 6.72719 4.27284H13.2726C13.7226 4.27284 14.0908 4.64102 14.0908 5.09102V14.9092Z"
                      fill="#A155B9"
                    />
                  </svg>
                  <p className="pl-1">{t("SAVE")}</p>
                </>
              )}
            </div>
            <Button
              intent="primary"
              className="w-1/2"
              size={"big"}
              text={isApplied ? t("SENT") : t("SEND")}
              disabled={isApplied}
              rounded="full"
              weight="bold"
              textClassName="text-sm"
              onClick={() => setVacancyId(vacancy.id)}
            />
          </div>
        )}
        {editorState.getCurrentContent().hasText() && (
          <div className="mt-5 flex flex-col gap-4">
            <Typography type="h5" weight="medium">
              {t("VACANCY_DESCRIPTION")}
            </Typography>
            <Editor editorState={editorState} onChange={() => {}} readOnly />
          </div>
        )}
        {vacancy.show_salary !== 0 && vacancy.salary_period === "monthly" && (
          <>
            <div className="mt-9">
              <Typography type="h4" weight="bold">
                {t("SALARY_ANALYTICS")}
              </Typography>
              <div className="mt-4 flex items-center gap-1">
                {/* <Image src='/images/info.svg' width={20} height={20} alt='attention' /> */}
                <Typography type="text">
                  {t("SALARY_STATUS_BY_AI")}
                </Typography>
              </div>
            </div>

            <AverageSalaryStatistic
              avgSalary={getAverageVacancySalary(vacancy.salary_from, vacancy.salary_from, vacancy.salary_type)}
            />
          </>
        )}
        {vacancy.company && (
          <div className="mt-6">
            {!isSingleVacancyPage && (
              <Typography type="h4" weight="bold" className="mb-4">
                {t("ABOUT_COMPANY")}
              </Typography>
            )}

            <div>
              <ACCHeader>
                <ACCHeaderLogoCompanyWrapper>
                  <div className="relative h-12 w-12">
                    <CompanyLogo
                      logo_version={vacancy.company.logo_version}
                      company_id={vacancy.company.id}
                      has_logo={vacancy.company?.has_logo}
                    />
                  </div>
                  <ACCHeaderCompanyWrapper>
                    <Typography type="button" weight="bold" color="dark">
                      {vacancy.company.brand_name}
                    </Typography>
                    <Typography type="mini">{vacancy.company?.legal_city_title}</Typography>
                  </ACCHeaderCompanyWrapper>
                </ACCHeaderLogoCompanyWrapper>
              </ACCHeader>
              {vacancy.company.description && (
                <Typography type="text" className="mt-4 line-clamp-none whitespace-pre-wrap">
                  {vacancy.company.description}
                </Typography>
              )}
              <ACCNotificationButton
                onClick={() => handleCompanySubscribe()}
                className={cx(
                  isCompanyLoading && "opacity-50",
                  "mt-4 flex w-full items-center justify-center",
                  Cookie.get(ACCESS_TOKEN_NAME) ? "visisble" : "invisible"
                )}
                disabled={isCompanyLoading}
              >
                {subscribedCompanyIds?.includes(vacancy.company.id) ? (
                  <>
                    <Typography type="small" weight="medium" className="text-primary-100">
                      {t("SUBSCRIBED")}
                    </Typography>
                  </>
                ) : (
                  <>
                    <Typography type="small" weight="medium" className="text-primary-100">
                      {t("SUBSCRIBE")}
                    </Typography>
                  </>
                )}
              </ACCNotificationButton>
            </div>
          </div>
        )}
        {similarVacancies.length > 0 && (
          <div className="mt-4">
            <Typography type="h5" weight="bold" className="mb-4">
              {t("SIMILAR_VACANCIES")}
            </Typography>

            <VacanciesListBoxList>
              {similarVacancies.map((vacancy: any) => (
                <div
                  onClick={() => {
                    openSimilarVacancy(vacancy)
                    getSimilarVacancies(vacancy)
                    scrollToTop()
                  }}
                  key={vacancy.id}
                  className={cx(
                    "group flex cursor-pointer gap-3 border-l-4 border-r border-white pl-3 pt-3 hover:border-l-primary-100"
                  )}
                >
                  <div className="relative flex h-11 w-11 shrink-0 items-center justify-center overflow-hidden rounded-full border border-neutral-40 bg-white">
                    <img
                      className="rounded-full p-1"
                      src={
                        vacancy?.company?.has_logo
                          ? `${STATIC_URL}/myjobs/company/${vacancy.company?.id}.jpg`
                          : "/defaults/business_center.svg"
                      }
                      alt={"s"}
                    />
                  </div>
                  <div className="flex-1 border-b border-neutral-40 pb-3">
                    <div className="flex items-center gap-2">
                      {vacancy.is_vip && <Vip />}
                      <Typography type="h5" weight="bold" className={cx("mr-2 line-clamp-1")}>
                        {vacancy.title}
                      </Typography>
                      <Image
                        src={vacancy.is_saved ? "/images/bookmark_border_selected.svg" : "/images/bookmark_border.svg"}
                        width={14}
                        height={18}
                        alt="bookmark"
                        className={cx("ml-auto mr-3 group-hover:block md:hidden")}
                      />
                    </div>

                    <Typography type="text" className="mt-0.5">
                      {vacancy.company?.brand_name}
                    </Typography>
                    <Typography type="text" className="mt-1 line-clamp-1">
                      {renderVacancyLocation(vacancy.country)}
                    </Typography>
                    <div className="jsutify-between mt-2 flex items-center">
                      <Typography type="h5" weight="medium">
                        {renderVacancySalary(vacancy.salary_from, vacancy.salary_to, vacancy.show_salary)}
                      </Typography>
                      <Typography type="small" className="ml-1">
                        {vacancy.salary_type !== 'negotiable' && ('/' + t(vacancy.salary_period?.toUpperCase() || ""))}
                      </Typography>
                      <Typography type="small" className="ml-auto mr-3 text-secondary-50">
                        {moment(vacancy.created_at).format("DD.MM.YYYY")}
                      </Typography>
                    </div>
                  </div>
                </div>
              ))}
            </VacanciesListBoxList>
          </div>
        )}
      </div>

      <VacancyItemScrollHeader
        divRef={divRef}
        title={vacancy.title}
        vacancy_id={vacancy.id}
        companyTitle={vacancy.company.brand_name}
        location={`${vacancy.country.city ? `${vacancy.country.city.title} , ` : ""}` + `${vacancy.country.title}`}
      />
    </div>
  )
}
interface RenderBenefitSvgProps {
  icon: string
  id: number
}
const RenderBenefitSvg = ({ icon, id }: RenderBenefitSvgProps) => {
  return <div dangerouslySetInnerHTML={{ __html: icon }} />
}

export default VacancyMobileContent
